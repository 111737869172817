import { connect } from 'datocms-plugin-sdk';
import { render } from './utils/render';
import ConfigScreen from './entrypoints/ConfigScreen';
import 'datocms-react-ui/styles.css';
import Sidebar from "./components/sidebar"

const dontRenderForApiKeys = ['usp-bar']

connect({
  renderConfigScreen(ctx) {
    return render(<ConfigScreen ctx={ctx} />);
  },
  itemFormSidebarPanels(itemType, ctx) {
    if (dontRenderForApiKeys.includes(itemType.attributes.api_key)) {
      return [];
    }
    return [
      {
        id: 'triggerTranslation',
        label: 'Trigger Translation',
      }
    ]
  },
  renderItemFormSidebarPanel(sidebarPaneId, ctx) {
    render(<Sidebar ctx={ctx} />)
  },
});
