import { RenderConfigScreenCtx } from 'datocms-plugin-sdk'
import {
  Button,
  Canvas,
  SwitchField,
  TextField,
  Form,
  FieldGroup,
} from 'datocms-react-ui'
import { Form as FormHandler, Field } from 'react-final-form'

type Props = {
  ctx: RenderConfigScreenCtx;
};
type FirstInstallationParameters = {};
type ValidParameters = {
  devMode: boolean;
  url: string,
  deeplApiKey: string,
};
type Parameters = FirstInstallationParameters | ValidParameters;

export default function ConfigScreen({ctx}: Props) {
  return (
    <Canvas ctx={ctx}>
      <FormHandler<Parameters>
        initialValues={ctx.plugin.attributes.parameters}
        validate={(values) => {
          const errors: Record<string, string> = {}
          if (!('url' in values) || !values.url) {
            errors.url = 'This field is required!'
          }
          if (!('deeplApiKey' in values) || !values.deeplApiKey) {
            errors.deeplApiKey = 'This field is required!'
          }
          return errors
        }}
        onSubmit={async (values) => {
          await ctx.updatePluginParameters(values)
          await ctx.notice('Settings updated successfully!')
        }}
      >
        {({handleSubmit, submitting, dirty}) => (
          <Form onSubmit={handleSubmit}>
            <FieldGroup>
              <Field name="url">
                {({input, meta: {error}}) => (
                  <TextField
                    id="url"
                    label="URL"
                    hint="URL to translate cloud function"
                    placeholder="URL"
                    required
                    error={error}
                    {...input}
                  />
                )}
              </Field>
              <Field name="deeplApiKey">
                {({input, meta: {error}}) => (
                  <TextField
                    id="deeplApiKey"
                    label="DeepL API Key"
                    hint="API Key to use DeepL's APIs"
                    placeholder="DeepL API Key"
                    required
                    error={error}
                    {...input}
                  />
                )}
              </Field>
              <Field name="devMode">
                {({input, meta: {error}}) => (
                  <SwitchField
                    id="devMode"
                    label="Enable development mode?"
                    hint="Log debug information in console"
                    error={error}
                    {...input}
                  />
                )}
              </Field>
            </FieldGroup>
            <Button
              type="submit"
              fullWidth
              buttonSize="l"
              buttonType="primary"
              disabled={submitting || !dirty}
            >
              Save settings
            </Button>
          </Form>
        )}
      </FormHandler> </Canvas>
  )
}
