import { RenderItemFormSidebarPanelCtx } from 'datocms-plugin-sdk';
import { SchemaTypes } from '@datocms/cma-client';
import { Button, Canvas } from 'datocms-react-ui'
import { useState } from "react"

type PropTypes = {
  ctx: RenderItemFormSidebarPanelCtx;
};

export default function Sidebar({ ctx }: PropTypes) {
  const otherLocales = ctx.site.attributes.locales.filter(l => l !== 'en')
  const { devMode, url, deeplApiKey } = ctx.plugin.attributes.parameters
  const { currentUserAccessToken } = ctx
  const [languages, setLanguages] = useState(otherLocales)
  const [overwrite, setOverwrite] = useState(false)
  const [isTranslating, setIsTranslating] = useState(false)
  const languageNames = new Intl.DisplayNames(['en'], { type: 'language' });

  if (typeof url !== 'string' || !url) {
    return <Canvas ctx={ctx}>
      <p>Plugin config for <code>URL</code> is missing</p>
    </Canvas>
  }

  if (typeof deeplApiKey !== 'string' || !deeplApiKey) {
    return <Canvas ctx={ctx}>
      <p>Plugin config for <code>DeepL API Key</code> is missing</p>
    </Canvas>
  }

  if (!currentUserAccessToken) {
    return <Canvas ctx={ctx}>
      <p>Cannot access current user's access token. The plugin's additional permissions is not configured properly.</p>
    </Canvas>
  }

  if (ctx.itemStatus === 'new' || ctx.isFormDirty) {
    return <Canvas ctx={ctx}>
      <p>Only saved items can be translated.</p>
    </Canvas>
  }

  function toggleLanguage(locale: string) {
    return () => {
      setLanguages(languages.includes(locale) ? languages.filter(l => l !== locale) : languages.concat([locale]))
    }
  }

  function onClick(url: string, currentUserAccessToken: string, deeplApiKey: string) {
    return async () => {
      if (!ctx.item?.id) {
        return ctx.alert('This record has no ID and cannot be translated')
      }
      setIsTranslating(true)

      const result = await triggerTranslateRecord({
        url,
        currentUserAccessToken,
        deeplApiKey,
        environment: ctx.environment,
        recordId: ctx.item?.id,
        languages,
        overwrite
      })
      setIsTranslating(false)
      if (devMode) {
        console.log('translation api result', result)
      }
      if (!result.ok) {
        return ctx.alert('Error translating record: ' + result.error)
      }
      return ctx.notice(formatResultMessage(result.result))
    }
  }

  return <Canvas ctx={ctx}>
    <p>Translate the current, English version of this record using Deepl.</p>
    <p>Select languages to translate to</p>
    <ul>
      {otherLocales.map(locale => {
        return <li key={locale}>
          <label>
            <input type="checkbox" checked={languages.includes(locale)} onChange={toggleLanguage(locale)}/> {locale} - {languageNames.of(locale)} <RecordLink item={ctx.item} locale={locale}/>
          </label>
        </li>
      })}
    </ul>
    <p>
      <label>
        <input type="checkbox" checked={overwrite} onChange={() => {setOverwrite(!overwrite)}}/> Overwrite existing translation
      </label>
    </p>
    <Button onClick={onClick(url, currentUserAccessToken, deeplApiKey)} disabled={isTranslating || !languages.length} fullWidth buttonSize="xxs">{buttonText(isTranslating)}</Button>
  </Canvas>
}

interface TranslationResult {
  [lang: string]: "translated" | "skipped" | "overwritten" | "unchanged"
}

function formatResultMessage(result: TranslationResult) {
  const results = Object.values(result)
  if (results.every(r => r === 'skipped')) {
    return 'Translation(s) already existed so nothing needed to be done! Use overwrite to replace the existing translation(s).'
  }
  if (results.every(r => r === 'unchanged')) {
    return 'The new translation(s) and the existing one(s) were exactly the same so the record wasn\'t updated'
  }
  const note: string = 'It may take a second for the translation to be visible in Dato. Don\'t manipulate the record in the meantime.'
  if (results.every(r => r === 'overwritten')) {
    return `The existing translation(s) have been overwritten. ${note}`
  }
  return `The record has been translated. ${note}`
}

function buttonText(isTranslating: boolean): string {
  if (isTranslating) {
    return 'Translating…'
  }
  return 'Translate this record using DeepL'
}

interface TriggerTranslateRecordParams {
  url: string
  currentUserAccessToken: string,
  deeplApiKey: string,
  environment: string
  recordId: SchemaTypes.ItemIdentity
  languages: string[]
  overwrite: boolean
}

async function triggerTranslateRecord({url, currentUserAccessToken, deeplApiKey, environment, recordId, languages, overwrite}: TriggerTranslateRecordParams) {
  const body = {
    environment,
    recordId,
    languages,
    overwrite,
  }

  const res = await fetch(url, {
    method: 'POST',
    headers: {
      'X-Auth-Dato': currentUserAccessToken,
      'X-Auth-DeepL': deeplApiKey,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })
  return res.json()
}

function RecordLink({item, locale}: { item: SchemaTypes.Item | null, locale: string }) {
  if (!item || !item.attributes.slug) {
    return null
  }
  return <a href={`https://www.soundtrackyourbrand.com/${item.attributes.slug}?lang=${locale}`} title="See record on storefront" target="_blank" rel="noreferrer">
    👀
  </a>
}

